import {
  Chart, Title, Tooltip, Legend, LinearScale, CategoryScale, TimeScale, BarElement, PointElement, BarController, TimeSeriesScale
} from 'chart.js';

Chart.register(Title, Tooltip, Legend, LinearScale, CategoryScale, TimeScale, BarElement, PointElement, BarController, TimeSeriesScale);

import 'chartjs-adapter-spacetime';
import chroma from "chroma-js";
import spacetime from 'spacetime';

export default async function renderHistoryChart() {

  const apiData = await fetch('/api/prospect-park/history.json');
  const parsedData = (await apiData.json()).data;

  const ctx = document.getElementById('historical-flights-chart-root');
  const colorScale = chroma.scale([
    '#1a5fb4', '#7050cc', '#a341c2', '#c93b95', '#e04657', '#e66100',
  ]).domain([0, 2, 5, 20, 25, 30])

  const chart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: parsedData.map((i, idx, arr) => {
        return new Date(i.lookback_start).toLocaleString('en-US', {'timezone': 'America/New_York'})}
      ),
      datasets: [{
        label: 'Planes in last hour',
        data: parsedData.map((i, idx, arr) => {return i.count}),
        barBackgroundColor: parsedData.map((i, idx, arr) => {return colorScale(i.count)}),
        fill: true,
        borderColor: 'lightgrey',
        borderSkipped: true,
        tension: 0.3,
        categoryPercentage: 1.0,
        barPercentage: 1.0
      }]
    },
    options: {
      maintainAspectRatio: false,
      transitions: {
        active: {
          animation: {
            duration: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          type: 'linear',
          beginAtZero: true,
        },
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'EE, M/d'
            }
          },
          ticks: {
            align: 'start',
          },
          grid: {
            offset: false,
            lineWidth: 2,
            color: '#4a4b4d',
            z: 10
          }
        }
      }
    }
  });

  document.getElementById('history-period-select').addEventListener('change', (evt) => {
    const newVal = evt.target.value;
    const now = spacetime.now('UTC'); // TODO: use monitoredarea tz
    if (newVal === 'week') {
      chart.options.scales.x.min = parsedData[0].lookback_start;
      chart.options.scales.x.time = {
        unit: 'day',
        displayFormats: {
          day: 'EE, M/d'
        }
      }
      chart.options.scales.x.ticks.stepSize = 1;
    }
    else if (newVal === 'day') {
      console.log(now)
      chart.options.scales.x.min = now.startOf('day').goto('America/New_York').format('iso');
      console.log(chart.options.scales.x.min)
      chart.options.scales.x.time = {
        unit: 'minute',
        unitStepSize: 60,
        displayFormats: {
          day: 'h:mm a'
        }
      }
      chart.options.scales.x.ticks.stepSize = 180;
    }
    chart.update();
  });
}

