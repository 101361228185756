// ## This all works assuming you add <div id="root"></div> somewhere
// ## For now we're just gonna use chart.js directly for simplicity.

// import { createApp } from 'vue'
// import { createPinia } from 'pinia'

// import 'leaflet/dist/leaflet.css';

// import AppRoot from './components/AppRoot.vue';


// const pinia = createPinia();
// const app = createApp(AppRoot);

// app.use(pinia)
// app.mount('#root')

import renderHistoryChart from './historyChart.js';

if (document.getElementById('historical-flights-chart-root')) {
  renderHistoryChart();
}

console.log('noisy.today app loaded');